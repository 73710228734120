<template>
  <v-scale-transition>
    <kits-panel :kits-items="managementKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Management Report
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      managementKits: [
        {
          icon: 'mdi-calendar',
          path: '/management/daily_view',
          title: 'Daily Excerpt',
          desc: 'View daily reports & statistics.',
        },
        {
          icon: 'mdi-calendar-blank',
          path: '/management/monthly_view',
          title: 'Monthly Excerpt',
          desc: 'View monthly reports & statistics.',
        },
        {
          icon: 'mdi-calendar-blank',
          path: '/management/reimbursement_view',
          title: 'Reimbursement Excerpt',
          desc: 'View reimbursement reports.',
        },
      ],
    }
  },
}
</script>
